.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  box-sizing: border-box;
}

.subheader {
  background: #fff;
  position: relative;
  z-index: 16;
}

.text {
  font-size: 12px;
  line-height: 150%;
  color: #171717;
}

.links {
  display: flex;
  gap: 40px;
}

.link {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  line-height: 18px;
  color: #171717;
  text-decoration: underline;
  text-decoration-color: transparent;

  transition: color 0.3s ease-in-out, text-decoration-color 0.3s ease-in-out;
}

.linkNum {
  color: #13ad37;
  text-decoration-color: #13ad37;
}