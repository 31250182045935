.label {
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
}

.label_hidden {
  opacity: 0;
}

.input {
  display: none;
}

.checkbox__wrapper {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 16px;
  border: 2px solid #fff;
  background-color: #14b339;
}

.checkbox__wrapper > svg {
  display: none;
}

.input:checked + .checkbox__wrapper {
  background-color: #fff;
}

.input:checked + .checkbox__wrapper > svg {
  display: block;
}

.checkbox__text {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}

.checkbox__text_light {
  color: #000;
}

.checkbox__wrapper_light {
  border: 2px solid #14b339;
  background-color: #fff;
}

.input:checked + .checkbox__wrapper_light {
  background-color: #14b339;
}

.checkbox__error {
  background-color: #fff;
  border: 2px solid red;
}

@media (max-width: 767px) {
  .checkbox__text {
    font-size: 11px;
    line-height: 12px;
  }

  .checkbox__text_light {
    font-size: 9px;
  }
}
