.root {
  background: #262626;
  padding: 50px 0 24px;
  border-radius: 24px 24px 0 0;
}

.logo {
  display: block;
  margin-bottom: 22px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}



.icons {
  display: flex;
  gap: 8px;

}
.icons svg{
  cursor: pointer;
}

.wrapper:first-of-type {
  padding-bottom: 37px;
}

.wrapper:last-of-type {
  padding-top: 15px;
  justify-content: flex-start;
  align-items: center;
}

.wrapper:first-of-type:after {
  content: '';
  opacity: 0.1;
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
  left: 0;
  background: #ffffff;
}

.col:last-of-type {
  display: flex;
  flex-direction: column;
}

.title {
  margin: 0 0 27px;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.socials {
  display: flex;
  align-items: center;
  gap: 8px;
}

.social {
  width: 30px;
  height: 30px;
  background: #3d3d3d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.social:after {
  content: '';
  position: absolute;
  z-index: 1;
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: transparent;
}

.social img {
  flex: none;
  position: relative;
  z-index: 2;
}

.social:hover:after {
  background: #525252;
  transform: scale(1.1);
}

.info__link {
  display: block;
  font-size: 25px;
  line-height: 32px;
  margin-bottom: 24px;
  color: #ffffff;
  opacity: 0.6;

  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out;
}

.info__link:hover {
  opacity: 1;
}

.info__link:first-of-type {
  background: linear-gradient(275.26deg, #13ad37 13.05%, #5dad13 82.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 8px;
  opacity: 1;
  font-weight: 700;
}

.info__link:last-of-type:hover {
  color: #119d32;
}

.info__link:last-of-type {
  font-size: 14px;
  line-height: 18px;
  opacity: 1;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.link {
  color: #ffffff;
  opacity: 0.6;
  font-size: 14px;
  line-height: 17px;

  transition: opacity 0.3s ease-in-out;
}

.link:hover {
  opacity: 1;
}

.callback {
  margin-top: auto;
  max-width: 124px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  padding: 8.5px 30px;
  box-sizing: border-box;
}

.callback:before {
  background: linear-gradient(275.96deg, #13ad37 10.46%, #5dad13 85.64%);
}

.callback:after {
  background: #119d32 !important;
}

.copy,
.policy {
  color: #ffffff;
  font-size: 12px;
  line-height: 13px;
}

.policy {
  text-decoration: underline;
  text-decoration-color: #ffffff;
  margin-left: 130px;

  transition: color 0.3s ease-in-out, text-decoration-color 0.3s ease-in-out;
}

.policy:hover {
  color: #13ad37;
  text-decoration-color: #13ad37;
}

.copy__wrapper {
  display: flex;
  align-items: center;
}

.copy__link {
  margin-left: auto;
}

@media (min-width: 1600px) {
  .title {
    font-size: 20px;
    line-height: 30px;
  }

  .link {
    font-size: 16px;
    line-height: 19px;
  }

  .info__link:first-of-type {
    font-size: 28px;
    line-height: 36px;
  }

  .info__link {
    font-size: 28px;
    line-height: 36px;
  }

  .info__link:last-of-type {
    font-size: 16px;
    line-height: 21px;
  }

  .callback {
    font-size: 14px;
    line-height: 17px;
    padding: 9px 35px;
  }

  .copy,
  .copy__link {
    font-size: 12px;
    line-height: 16px;
  }
}
@media (max-width: 1366px) {
  .policy {
    font-size: 10px;
  }
}

@media (max-width: 1279px) {
  .wrapper:first-of-type {
    flex-wrap: wrap;
  }
  .col {
    max-width: 250px;
    width: 100%;
  }
  .col:last-of-type {
    margin-top: 32px;
  }
}

@media (max-width: 990px) {
  .wrapper:first-of-type:after {
    width: calc(100% - 40px);
    left: 20px;
  }
}

@media (max-width: 789px) {
  .col:first-of-type {
    order: 1;
  }
  .col:nth-child(2) {
    order: 3;
  }
  .col:nth-child(3) {
    order: 4;
  }
  .col:nth-child(2),
  .col:nth-child(3) {
    margin-top: 32px;
  }
  .col:last-of-type {
    order: 2;
    margin-top: 0;
  }
  .copy__wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .copy {
    display: block;
    margin-bottom: 9px;
  }
  .policy {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .root {
    border-radius: 0;
  }
}

@media (max-width: 539px) {
  .col {
    max-width: 100%;
  }
  .col:not(.col:first-of-type) {
    margin-top: 35px;
  }
  .col:last-of-type {
    order: 4;
  }
  .logo {
    margin-bottom: 24px;
  }
  .callback {
    margin: 48px 0 0;
  }
}

@media (max-width: 375px) {
  .callback {
    max-width: 328px;
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    padding: 14px;
  }
}
