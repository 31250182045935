.input {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #ffffff;
  outline: none;
  font-family: inherit;
  color: #ffffff;
  font-size: 16px;
  width: 100%;
  padding: 21px 0 10px;
}

.label {
  position: relative;
}

.label:hover .name {
  top: 0;
}

.name {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  position: absolute;
  top: 16px;
  left: 0;
  transition: all 0.2s ease-in-out;
}

.input:not(:placeholder-shown) + .name,
.input:focus + .name {
  top: 0;
  font-size: 10px;
  line-height: 11px;
  opacity: 0.6;
}

.error {
  font-size: 14px;
  color: #fff;
  line-height: 21px;
  padding-left: 29px;
  margin-top: 7px;
  position: relative;
}

.error:after {
  content: 'i';
  position: absolute;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  color: #14b339;
}

.label_light {
  margin-bottom: 32px;
}

.label_hidden {
  opacity: 0;
}

.input_light {
  border-bottom: 2px solid #13ad37;
  color: #000;
}

.label_light:last-child {
  margin-bottom: 48px;
}

.name_light {
  color: #bfbfbf;
  font-size: 18px;
}

.error_light {
  color: #000;
}

.error_light::after {
  background-color: #14b339;
  color: #fff;
}

@media (max-width: 767px) {
  .label_light {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 24px;
  }

  .label_light:last-child {
    margin-bottom: 32px;
  }
}
