.root {
  position: sticky;
  z-index: 15;
  top: 0;
  padding: 16px 0 24px;
  box-sizing: border-box;
  background: #ffffff;
}

.root_bordered:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #d9d9d9;
}

.menu {
  margin-left: 64px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.menu_mob {
  position: absolute;
  top: 71px;
  left: 0;
  width: 100%;
  height: calc(100vh - 71px);
  margin: 0;
  background: #ffffff;
  flex-direction: column;
  overflow: auto;
  box-sizing: border-box;
  padding: 50px 0;
  gap: 0;
}

.menu__links {
  max-width: 328px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 28px;
  align-items: flex-start;
}

.menu__links .link {
  font-size: 20px;
  line-height: 24px;
}

.menu__links .link:after {
  width: calc(100% - 20px);
  transition: width 0.3s ease-in-out;
}

.menu__links .link:hover:after {
  width: 0;
}

.contacts {
  padding: 48px 16px;
  box-sizing: border-box;
  background: #fafafa;
  border-radius: 24px 24px 0px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contacts .callback {
  margin: 0;
}

.contact:first-of-type {
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #13ad37;
  margin-bottom: 8px;

  transition: text-decoration-color 0.3s ease-in-out;
}

.contact:first-of-type:hover {
  text-decoration-color: transparent;
}

.contact:last-of-type {
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  text-decoration: none;
  color: #8c8c8c;
  margin-bottom: 24px;

  transition: color 0.3s ease-in-out;
}

.contact:last-of-type:hover {
  color: #13ad37;
}

.menu_mob_hidden {
  overflow: hidden;
}

.contacts__text {
  font-size: 12px;
  line-height: 18px;
  color: #171717;
  text-align: center;
  margin: 0 0 40px;
}

.products {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 71px);
  margin: 0;
  background: #ffffff;
  flex-direction: column;
  overflow: auto;
  box-sizing: border-box;
  padding: 48px 0;
  gap: 0;
  z-index: 3;
}

.products__header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  max-width: 328px;
  width: 100%;
  margin: 0 auto 25px;
}

.button_back {
  position: absolute;
  left: 10px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 20px;
  height: 20px;
  background: transparent;
  padding: 0;
}


.products__title {
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #119d32;
}

.categories {
  position: relative;
  padding: 17px 16px 0;

  display: flex;
  flex-direction: column;
  gap: 16px;

  max-width: 328px;
  width: 100%;
  margin: 0 auto;
}

.categories:before {
  content: '';
  position: absolute;
  width: calc(100% - 32px);
  top: 0;
  left: 16px;
  height: 1px;
  background: #f0f0f0;
}

.category {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 17px;

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #171717;
}

.category:after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: #f0f0f0;
}

.category__arrow {
  transform: rotate(180deg);
  flex: none;
}
.searchWrapper {
  position: relative;
  cursor: pointer !important;
}
.over {
  position: absolute;
  width: 100%;
  height: 100%;
}

.right {
  display: flex;
  align-items: center;
  gap: 15px;
}
.xmark {
  position: absolute;
  bottom: 2px;
  right: -6px;
  cursor: pointer;
  z-index: 10;
}

.products__title .burger {
  margin-right: 16px;
}
.search {
  cursor: pointer;
}
.activeSearch circle, .activeSearch path {
  stroke: #FFFFFF;
}
.activeSearch rect{
  fill: #13AD37;
}
.wrapper {

  display: flex;
  align-items: center;
}

.wrapper_mob {
  justify-content: space-between;
}

.link {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #171717;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}

.logo__wrapper,
.phone {
  position: relative;
  z-index: 1;
}

.link__text {
  position: relative;
  z-index: 2;
}

.logo {
  position: relative;
  z-index: 2;
}

.link:after {
  content: '';
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 0;
  height: 6px;
  background: #13ad37;

  transition: width 0.3s ease-in-out;
}

.link:hover:after {
  width: calc(100% - 20px);
}

.button__link {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #171717;
  display: flex;
  align-items: center;
  background: #f7f7f7;
  padding: 11px 24px;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: transform 0.3s ease-in-out;
}

.button__link > .burger {
  height: 10px;
}

.button__link > span {
  position: relative;
  z-index: 2;
}

.button__link:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  border-radius: 50px;
  transition: top 0.3s ease-in-out, height 0.3s ease-in-out;
}

.button__link:hover:before {
  top: -4px;
  height: calc(100% + 8px);
}

.button__link_wrapper {
  position: relative;
}

.button__link .burger {
  margin-right: 10px;
}

.burger {
  height: 12px;
  width: 13px;
  align-items: center;
  position: relative;
}

.burger__inner {
  position: absolute;
  width: 13px;
  height: 2px;
  background: #13ad37;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.burger__inner:nth-child(1) {
  top: 0;
}

.burger__inner:nth-child(2) {
  top: 4px;
  opacity: 1;
}

.burger__inner:nth-child(3) {
  top: 8px;
}

.burger_active .burger__inner:nth-child(1) {
  transform: rotate(45deg);
  top: 4px;
}
.burger_active .burger__inner:nth-child(3) {
  transform: rotate(-45deg);
  bottom: 6px;
  top: auto;
}
.burger_active .burger__inner:nth-child(2) {
  opacity: 0;
}

.burger__button {
  transform: scale(1.3);
  position: relative;
  z-index: 2;
}

.callback {
  padding: 11px 35px;
  position: relative;
  z-index: 2;
}
.nav {
  width: 100%;
  display: flex;
}
.callSearch {
  margin-left: auto;
  display: flex;
  gap: 24px;
  align-items: center;
}

.callback:before {
  background: linear-gradient(275.36deg, #13ad37 11.87%, #5dad13 86.48%);
}

.callback:after {
  background: #119d32;
}

.menu_open {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 905px;
  left: -66px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 0 0 24px 24px;
  padding: 96px 64px 64px 64px;
  animation: menuVisible 0.2s ease-in-out forwards;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 48px 64px;
}

.open:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  left: 0;
  background: #f0f0f0;
}

.menu__item {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #171717;
  opacity: 0;
  transform: translateY(-50px);
}

.menu__item:nth-child(1) {
  animation: menuItemSlideDown 0.2s ease-in-out 0.2s forwards;
}
.menu__item:nth-child(2) {
  animation: menuItemSlideDown 0.2s ease-in-out 0.2s forwards;
}
.menu__item:nth-child(3) {
  animation: menuItemSlideDown 0.2s ease-in-out 0.2s forwards;
}
.menu__item:nth-child(4) {
  animation: menuItemSlideDown 0.2s ease-in-out 0.4s forwards;
}
.menu__item:nth-child(5) {
  animation: menuItemSlideDown 0.2s ease-in-out 0.4s forwards;
}
.menu__item:nth-child(6) {
  animation: menuItemSlideDown 0.2s ease-in-out 0.4s forwards;
}

.menu__item:nth-child(7) {
  animation: menuItemSlideDown 0.2s ease-in-out 0.6s forwards;
}
.menu__item:nth-child(8) {
  animation: menuItemSlideDown 0.2s ease-in-out 0.6s forwards;
}
.menu__item:nth-child(9) {
  animation: menuItemSlideDown 0.2s ease-in-out 0.6s forwards;
}

.menu__item:nth-child(10) {
  animation: menuItemSlideDown 0.2s ease-in-out 0.8s forwards;
}
.menu__item:nth-child(11) {
  animation: menuItemSlideDown 0.2s ease-in-out 0.8s forwards;
}
.menu__item:nth-child(12) {
  animation: menuItemSlideDown 0.2s ease-in-out 0.8s forwards;
}

.menu__item:nth-child(n+13)  {
  animation: menuItemSlideDown 0.2s ease-in-out 1s forwards;
}






.menu__item_img {
  width: 48px;
  margin-bottom: 24px;
  flex: none;
}

.menu__item span {
  display: inline;
  position: relative;
  overflow: hidden;

  transition: background 0.3s ease-in-out;
}

.menu__item:hover span {
  box-decoration-break: clone;
  background-repeat: no-repeat;
  background-image: linear-gradient(180deg, #13ad37, #13ad37);
  background-position: 0 120%;
  background-size: 100% 12px;
}

@media (min-width: 1600px) {
  .menu {
    margin-left: 96px;
    gap: 10px;
  }
  .menu_open {
    width: 1245px;
    left: -145px;
  }
}

@media (min-width: 1920px) {
  .button__link_wrapper:hover .menu_open {
    padding: 96px 108px 64px 104px;
  }
  .menu_open {
    width: 1225px;
    left: -100px;
    grid-template-columns: 261px 1fr 240px;
  }
}

@media (min-width: 2560px) {
  .menu_open {
    width: 1305px;
    gap: 48px 104px;
  }
}

@media (max-width: 1600px) {
  .menu {
    gap: 10px;
  }
}
@media (max-width: 1365px) and (min-width: 1280px) {
  .menu {
    margin-left: 48px;
    gap: 10px;
  }
}

@media (max-width: 1279px) and (min-width: 991px) {
  .logo {
    width: 150px;
  }

  .menu {
    margin-left: 32px;
    gap: 3px;
  }
  .callSearch {
    gap: 10px;
  }
  .link,
  .button__link {
    font-size: 12px;
    line-height: 15px;
  }

  .callback {
    font-size: 12px;
    line-height: 16px;
    padding: 10px 20px;
  }
  .link {
    white-space: nowrap;
  }
  .menu_open {
    left: -180px;
  }
}

@media (max-width: 990px) {
  .root {
    padding: 15px 0;
  }

  .menu_desk {
    display: none;
  }

  .icon {
    width: 24px;
  }

  .logo {
    width: 180px;
  }

  .button__link {
    font-size: 14px;
    line-height: 17px;
    padding: 20px 24px;
    justify-content: flex-start;
    max-width: 328px;
    width: 100%;
    margin-bottom: 70px;
    box-sizing: border-box;

    animation: menuItemSlideDown 0.3s ease-in-out forwards;
  }

  .button__link .burger {
    margin-right: 16px;
  }


  .link:nth-child(1) {
    animation: menuItemSlideDown 0.3s ease-in-out 0.3s forwards;
  }
  .link:nth-child(2) {
    animation: menuItemSlideDown 0.3s ease-in-out 0.4s forwards;
  }
  .link:nth-child(3) {
    animation: menuItemSlideDown 0.3s ease-in-out 0.5s forwards;
  }

  .contact {
    opacity: 0;
  }

  .contact:nth-child(1) {
    animation: menuItemSlideDown 0.3s ease-in-out 0.6s forwards;
  }
  .contact:nth-child(2) {
    animation: menuItemSlideDown 0.3s ease-in-out 0.7s forwards;
  }
  .contacts__text {
    opacity: 0;
    animation: menuItemSlideDown 0.3s ease-in-out 0.8s forwards;
  }

  .callback {
    max-width: 328px;
    width: 100%;
    padding: 19px 0;
    font-size: 14px;
    line-height: 18px;
    opacity: 0;
    animation: menuItemSlideDown 0.3s ease-in-out 0.9s forwards;
  }
}

@media (max-width: 375px) {
  .callback {
    max-width: 100%;
  }

  .contacts {
    align-items: flex-start;
  }
  .menu__links,
  .button__link {
    max-width: calc(100% - 32px);
  }

  .icon {
    width: 18px;
  }

  .logo {
    width: 155px;
  }

  .burger__button {
    transform: scale(1);
  }
  .categories {
    max-width: calc(100% - 32px);
  }
}

@keyframes menuItemSlideDown {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
