.title {
  font-size: 36px;
  line-height: 40px;
  margin: 0 0 24px;
}

.description {
  font-size: 18px;
  line-height: 27px;
  margin: 0 0 40px;
  color: #595959;
}

.description__submitted {
  margin: 0;
}

.modal {
  background-color: #fff;
  display: flex;
  border-radius: 24px;
  max-width: 672px;
  box-sizing: border-box;
  padding: 48px;
  width: calc(100% - 60px);
  position: relative;
  min-height: 571px;
}

.form_upper {
  display: flex;
  flex-direction: column;
  max-height: unset;
  gap: unset;
}

.form_lower {
  justify-content: flex-start;
}

.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.button {
  margin-right: auto;
  width: 177px;
  color: #fff;
  height: 48px;
  margin-bottom: 16px;
}

.button:before {
  background: linear-gradient(275.36deg, #13ad37 11.87%, #5dad13 86.48%);
}

.button::after {
  background: #119d32;
}

.link {
  color: #000;
}

.close {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .modal {
    padding: 32px 24px;
    width: calc(100% - 32px);
    min-height: unset;
  }

  .title {
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 16px;
  }

  .description {
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 40px;
  }

  .close {
    top: 20px;
    right: 20px;
    width: 16px;
    height: 16px;
  }
}
