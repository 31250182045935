.wrapper {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto auto 1fr auto;
}

@media (max-width: 990px) {
    .wrapper {
        grid-template-rows: auto 1fr auto;
    }
}