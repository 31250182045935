.wrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
  margin-top: 24px;
  background: rgba(0,0,0,0.41);
}

.root {
  background: #FFFFFF;
  padding-top: 24px;
  padding-bottom: 16px;
  font-size: 14px;
  line-height: 150%;
  color: #171717;
}

.container {
  max-width: 688px;
  margin: 0 auto;
}

.inputWrapper {
  position: relative;
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 7px 0 0 36px;
  background: #FFFFFF;
  font-weight: 400;
  font-size: 21px;
  line-height: 130%;
  border: none;
  outline: none;
  border-bottom: 1px solid #F0F0F0;
  color: #171717;
  ::placeholder {
    color: #8C8C8C;
  }

}


.input:focus {
  border-bottom: 1px solid #13AD37 !important;
}

.search {
  position: absolute;
  top: 8px;
  left: 0;
}

.xmark {
  position: absolute;
  top: 15px;
  cursor: pointer;
  right: 0;
}

.hint {
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #171717;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 0;
  transition: all .2s;
  svg {
    circle {
      transition: all .2s;
    }
    path {
      transition: all .2s;
    }
  }

}
.hint:hover a{
  color: #13AD37;
}

.hint:hover svg {
  circle {
    stroke: #13AD37;
  }
  path {
    stroke: #13AD37;
  }
}

.button {
  padding: 9px;
  width: 100%;
}
.button:before {
  background: #FFFFFF;
  border: 2px solid #13AD37 !important;
}

.button:after {
  border-radius: 50px;
  background: #FFFFFF;
  border: 2px solid #13AD37 !important;
}

@media (max-width: 990px) {
  .root {
    background: #FFFFFF;
    padding: 24px 16px 16px;
    margin-top: -10px;
    font-size: 14px;
    line-height: 150%;
    color: #171717;

  }
  .wrapper hr {
    position: relative;
    z-index: 20;
  }
}