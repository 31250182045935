.root {
  border-radius: 50px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  background: transparent;
}

.root__inner {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
}

.root:before {
  content: '';
  position: absolute;
  z-index: 2;
  background: linear-gradient(275.36deg, #13ad37 11.87%, #5dad13 86.48%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  transition: opacity 0.3s ease-in-out, top 0.3s ease-in-out,
  height 0.3s ease-in-out;
}

.root:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  border-radius: 50px;
  background: #119d32;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: background 0.3s ease-in-out, opacity 0.2s ease-in-out,
  height 0.3s ease-in-out, top 0.3s ease-in-out;
}

.root:hover:before {
  z-index: 1;
  top: -4px;
  height: calc(100% + 8px);
}


.root:hover:after {
  opacity: 1;
  z-index: 2;
  top: -4px;
  height: calc(100% + 8px);
}

.fill {
  color: #13AD37;
}


.fill:before {
  background: #FFFFFF;
  border: 1px solid #13AD37;
}

.fill:after {
  border-radius: 50px;
  background: #FFFFFF;
  border: 1px solid #13AD37;
}
